<template>
    <div class="page first-story-page" @keyup.13="onSaveClick">
        <div class="page__title">
            <nav-back/>
            Настройка первой истории после инсталла
        </div>

        <label class="mt-4">История 18+</label>
        <b-form-select v-model.number="selectedAdultStory" :options="storiesOptions"/>

        <label class="mt-3">Альтернативная история</label>
        <b-form-select v-model.number="selectedAlternateStory" :options="storiesOptions"/>

        <ui-button @clicked="onSaveClick" class="first-story-page__button">Применить</ui-button>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        storiesOptions: [{value: 0, text: 'Ожидание'}],
        selectedAdultStory: 0,
        selectedAlternateStory: 0,
        adultStoryChanged: false,
        alternateStoryChanged: false,
    }),
    components: {
        NavBack: () => import('../../components/navigation/NavBack'),
        UiButton: () => import('../../components/ui/UiButton')
    },
    computed: {
        ...mapGetters('firebase', ['stories', 'economyConfig'])
    },
    created() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData).$on(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData);
    },
    beforeDestroy() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.setData);
    },
    async mounted() {
        await this.getConfig();
        this.setData();
    },
    watch: {
        selectedAdultStory(value, oldValue) {
            if (oldValue) this.adultStoryChanged = true;
        },
        selectedAlternateStory(value, oldValue) {
            if (oldValue) this.alternateStoryChanged = true;
        }
    },
    methods: {
        ...mapActions('firebase', ['getConfig', 'updateEconomyConfig']),
        setData() {
            this.storiesOptions = this.stories.map(story => {
                return {value: story.id, text: story.title};
            });

            this.selectedAdultStory = this.economyConfig.start_story_adult;
            this.selectedAlternateStory = this.economyConfig.start_story;
        },
        async onSaveClick() {
            const data = {};
            if (this.adultStoryChanged) data.start_story_adult = this.selectedAdultStory;
            if (this.alternateStoryChanged) data.start_story = this.selectedAlternateStory;
            if (!Object.keys(data).length) return;

            const response = await this.updateEconomyConfig(data);

            if (!response.error)
                bus.$emit('show-notification-message', 'Сохранено');
            else
                bus.$emit('show-notification-message', `Ошибка: ${response.error.toString()}`);
        }
    }
};
</script>

<style lang="scss">
.first-story-page {
    label {
        text-align: left;
    }

    select {
        max-width: 25%;
    }

    &__button {
        max-width: fit-content;
        margin-top: 16px;
    }
}
</style>
